input.Forgot {
    display: flex;
    border-radius: 0;
    border: 2px solid #7D7C7A;
    color: #7d7c7a;
    font-family: DIN-Medium;    
    font-size: 14px;
    font-style: normal;
    font-weight: 550;
    line-height: 138%;
    margin-top: .6rem;
    padding-left: 1rem;
    top: 8px;
    width: 339px;
    height: 40px;
    background-color: #141414;
}
button.Forgot {
    align-items: center;
    background-color: #685848;    
    display: flex;
    height: 40px;
    flex-direction: column;
    gap: 40px;
    justify-content: center;
    margin-top: 1rem;
    padding: 20px 20px;
    width: 339px;
    color: #e3dbd3;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 550;
    line-height: normal;
    text-transform: uppercase;
}